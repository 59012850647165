import React, { useState, useRef } from "react";
import { motion } from "framer-motion";
import toast, { Toaster } from "react-hot-toast";
import emailjs from "@emailjs/browser";
const Contact = () => {
  const formRef = useRef();
  const [form, setForm] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { target } = e;
    const { name, value } = target;

    setForm({ ...form, [name]: value });
  };
  //   Form Validation
  const validateName = () => {
    if (!form.name.trim() || form.name.trim().length < 4) {
      toast.error("Name is required and should be at least 4 characters long");
      return false;
    }
    return true;
  };

  const validateEmail = () => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!emailRegex.test(form.email.trim())) {
      toast.error("Invalid email address");
      return false;
    }
    return true;
  };

  const validateSubject = () => {
    if (!form.subject.trim() || form.subject.trim().length < 4) {
      toast.error(
        "Subject is required and should be at least 4 characters long"
      );
      return false;
    }
    return true;
  };

  const validateMessage = () => {
    if (!form.message.trim() || form.message.trim().length < 5) {
      toast.error(
        "Message is required and should be at least 5 characters long"
      );
      return false;
    }
    return true;
  };
  const validateForm = () => {
    let isValid = true;

    if (!validateName()) isValid = false;
    if (!validateEmail()) isValid = false;
    if (!validateSubject()) isValid = false;
    if (!validateMessage()) isValid = false;

    return isValid;
  };

  // Handle form submission

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    setLoading(true);

    emailjs
      .send(
        "service_d0pmyma",
        "template_9fuiuqg",
        {
          from: form.name,
          to_name: "Suliman",
          from_email: form.email,
          to_email: "suliman.badour1@gmail.com",
          message: form.message,
        },
        "mkz7oSd9ODDAsF9Iw"
      )
      .then(
        () => {
          setLoading(false);
          alert("Message sent successfully, I'll get back to you soon!");
          setForm({
            name: "",
            email: "",
            message: "",
            subject: "",
          });
        },
        (error) => {
          // Handle the error here
          setLoading(false);
          alert("Something went wrong, please try again later!");
        }
      );
  };
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      className="h-screen flex relative flex-col text-center
        md:text-center md:flex-row px-10 justify-evenly mx-auto items-center"
    >
      <Toaster position="top-center" reverseOrder={false} />
      <h3 className="absolute top-32 text-center uppercase tracking-[8px] p-0 text-gray-200 text-3xl md:pb-12">
        Get in touch.
      </h3>
      <h3 className="absolute pt-12  top-36 uppercase tracking-[3px] text-gray-200 text-sm">
        Contact.
      </h3>
      <div className="flex flex-col pt-12 top-0 space-y-10  md:pt-0">
        {/* Form starts here */}
        <form
          ref={formRef}
          onSubmit={handleSubmit}
          className="flex flex-col space-y-2 w-fit mx-auto pt-2"
        >
          <div className="flex space-x-2">
            <input
              className="contactInput"
              type="text"
              name="name" // Add this
              placeholder="Name"
              value={form.name}
              onChange={handleChange}
              required
              onBlur={validateName} // Add this
            />
            <input
              className="contactInput"
              type="email"
              name="email" // Add this
              placeholder="Email"
              value={form.email}
              onChange={handleChange}
              onBlur={validateEmail} // Add this
            />
            {/* {errors.email && (
            <p className="error-popup">{errors.email.message}</p>
          )} */}
          </div>
          <input
            className="contactInput"
            type="text"
            placeholder="Subject"
            name="subject" // Add this
            value={form.subject}
            onChange={handleChange}
            onBlur={validateSubject} // Add this
          />
          <textarea
            className="contactInput"
            placeholder="Message"
            name="message" // Add this
            value={form.message}
            onChange={handleChange}
            onBlur={validateMessage} // Add this
          />
          <button
            type="submit"
            className="bg-[#fff] py-5 px-6 rounded-md text-black
      text-lg font-bold hover:bg-[#9b89ecda] hover:text-[#fff] transition-all
      duration-300 ease-in-out"
          >
            {loading ? "Sending..." : "Send"}
          </button>
        </form>
      </div>
    </motion.div>
  );
};

export default Contact;
